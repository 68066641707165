import React, { useState } from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';
import { tint } from 'polished';
import get from 'lodash.get';
import GatsbyImage from 'gatsby-image';
import {
  stylizeFunction,
  themeVal,
  media,
  glsp,
  rgba,
  truncated
} from '@devseed-ui/theme-provider';
import { CollecticonCircleInformation } from '@devseed-ui/collecticons';

import Gridder from '../styles/gridder';
import { stripe } from '../styles/motifs';

const _tint = stylizeFunction(tint);

const renderPositionOverrides = ({ size }) =>
  size !== 'default' &&
  css`
    /* Overrides for GatsbyImage */
    .gatsby-image-wrapper {
      position: static !important;
      height: 100%;
      width: 100%;
    }
  `;

const MediaSelf = styled.figure`
  position: relative;
  ${renderPositionOverrides}
`;

const renderMediaItemInnerSize = ({ size }) => {
  switch (size) {
    case 'cover':
      return css`
        grid-column: full-start / full-end;
        height: 16rem;

        ${media.smallUp`
          height: 20rem;
        `}

        ${media.mediumUp`
          height: 24rem;
        `}

        ${media.largeUp`
          height: 32rem;
        `}

        ${media.xlargeUp`
          height: 40rem;
        `}
      `;
    case 'large':
      return css`
        grid-column: content-start / content-end;
      `;
    default:
      return css`
        height: 100%;
        width: 100%;

        video {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
      `;
  }
};

const renderDecoration = ({ decoration }) => {
  if (!decoration || decoration === 'none') return null;

  // The position on the grid defines where the decoration is placed
  // horizontally.
  let decoOnGrid = null;
  // Dectoration top or bottom only exist for the cover size and are always
  // placed on the right side.
  if (decoration === 'top' || decoration === 'bottom') {
    decoOnGrid = css`
      margin-right: -100vw;
      grid-column: content-3 / span 1;
      grid-row: 1;

      ${media.mediumUp`
        grid-column: content-6 / span 1;
      `}

      ${media.largeUp`
        grid-column: content-8 / span 1;
      `}
    `;
  }
  if (decoration === 'left') {
    decoOnGrid = css`
      margin-left: -100vw;
      grid-column: content-start / span 1;
      grid-row: 1;

      ${media.mediumUp`
        grid-column: content-2 / span 1;
      `}

      ${media.largeUp`
        grid-column: content-2 / span 1;
      `}

      ${media.xlargeUp`
        grid-column: content-3 / span 1;
      `}
    `;
  }
  if (decoration === 'right') {
    decoOnGrid = css`
      margin-right: -100vw;
      grid-column: content-4 / span 1;
      grid-row: 1;

      ${media.mediumUp`
        grid-column: content-7 / span 1;
      `}

      ${media.largeUp`
        grid-column: content-11 / span 1;
      `}

      ${media.xlargeUp`
        grid-column: content-10 / span 1;
      `}
    `;
  }
  let verticalPosition = null;
  if (decoration === 'top') {
    verticalPosition = css`
      top: -1rem;

      ${media.smallUp`
        top: -2rem;
      `}

      ${media.mediumUp`
        top: -4rem;
      `}

      ${media.largeUp`
        top: -6rem;
      `}
    `;
  }
  if (decoration === 'bottom') {
    verticalPosition = css`
      top: 100%;
      transform: translate(0, calc(-100% + 1rem));

      ${media.smallUp`
        transform: translate(0, calc(-100% + 2rem));
      `}

      ${media.mediumUp`
        transform: translate(0, calc(-100% + 4rem));
      `}

      ${media.largeUp`
        transform: translate(0, calc(-100% + 6rem));
      `}
    `;
  }
  if (decoration === 'left' || decoration === 'right') {
    verticalPosition = css`
      top: 100%;
      transform: translate(0, calc(-100% - 3rem));

      ${media.mediumUp`
        transform: translate(0, calc(-100% - 4rem));
      `}

      ${media.largeUp`
        transform: translate(0, calc(-100% - 6rem));
      `}

      ${media.xlargeUp`
        transform: translate(0, calc(-100% - 8rem));
      `}
    `;
  }

  return css`
    &::before {
      ${stripe};
      z-index: 10;

      ${decoOnGrid}
      ${verticalPosition}
    }
  `;
};

export const MediaItem = styled.div`
  position: relative;
  z-index: 2;
  grid-row: 1;

  ${media.mediumUp`
    grid-gap: ${glsp(0, themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-gap: ${glsp(0, themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(0, themeVal('layout.gap.xlarge'))};
  `}

  ${renderDecoration}

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

const MediaItemDefault = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  .gatsby-image-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
`;

const MediaItemInner = styled.div`
  ${renderMediaItemInnerSize}
  grid-row: 1;
  position: relative;
`;

const MediaAttribution = styled.p`
  position: absolute;
  top: ${glsp(1)};
  left: ${glsp(1)};
  z-index: 40;
  background: none;
  max-width: calc(100% - ${glsp(2)});
  display: inline-flex;
  color: ${themeVal('color.surface')};
  border-radius: ${themeVal('shape.ellipsoid')};
  padding: ${glsp(0, 0.25)};
  height: 1.5rem;
  font-size: 0.75rem;
  background: ${rgba(themeVal('color.base-500'), 0.32)};
  overflow: hidden;

  a,
  a:visited,
  a:not([class]),
  a:not([class]):visited {
    color: inherit;
  }

  a:hover {
    opacity: 1;
  }
`;

const MediaAttributionInner = styled.span`
  display: flex;
  flex-flow: nowrap;
  align-items: center;

  svg {
    opacity: 0.64;
    flex-shrink: 0;
  }

  strong {
    display: block;
    width: 100%;
    max-width: 0;
    overflow: hidden;
    font-weight: normal;
    white-space: nowrap;
    padding: ${glsp(0)};
    opacity: 0;
    transition: all 0.24s ease-in-out 0s;
  }

  ${({ hovering }) => hovering && '&,'}
  &:hover {
    strong {
      ${truncated()}
      max-width: 64vw;
      padding: ${glsp(0, 0.5, 0, 0.25)};
      opacity: 1;
    }
  }
`;

const MediaCaption = styled.figcaption`
  position: relative;
  z-index: 3;
  mix-blend-mode: multiply;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${_tint(0.32, themeVal('color.base'))};
  padding: ${glsp(1, themeVal('layout.gap.xsmall'))};
  width: 100%;
  max-width: 52rem;
  margin: 0 auto;

  ${media.smallUp`
    padding: ${glsp(1, themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    padding: ${glsp(2, themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    padding: ${glsp(2, themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    padding: ${glsp(2, themeVal('layout.gap.xlarge'))};
  `}
`;

const decoMatrix = {
  large: ['left', 'right'],
  cover: ['top', 'bottom']
};

const validateDeco = (size, deco) => {
  if (!deco || deco === 'none') return true;

  const allowed = decoMatrix[size];

  if (allowed && !allowed.includes(deco)) {
    const allStr = allowed.join(', ');
    throw `Invalid decoration [${deco}] for size [${size}]. Allowed decoration options [${allStr}]`;
  }
};

const MediaAttributionInfo = ({ attribution }) => {
  const [hovering, setHovering] = useState(false);

  if (!attribution?.name) return null;

  return (
    <MediaAttribution>
      {attribution.url ? (
        <MediaAttributionInner
          hovering={hovering}
          as='a'
          title='Learn about the author'
          href={attribution.url}
          onTouchEnd={(e) => {
            if (!hovering) {
              e.preventDefault();
            }
            setHovering(!hovering);
          }}
        >
          <CollecticonCircleInformation />
          <strong>Image by {attribution.name}</strong>
        </MediaAttributionInner>
      ) : (
        <MediaAttributionInner>
          <strong>Image by {attribution.name}</strong>
        </MediaAttributionInner>
      )}
    </MediaAttribution>
  );
};

MediaAttributionInfo.propTypes = {
  attribution: T.shape({
    name: T.string,
    url: T.string
  })
};

const MediaCaptionElement = (props) => {
  const { children, dangerouslySetInnerHTML } = props;

  const html = get(dangerouslySetInnerHTML, '__html');
  const hasCaption = !!html || !!children;
  // Use html if provided, otherwise default to children.
  // HTML is needed for the rendered markdown content.
  const captionProps = html
    ? {
        dangerouslySetInnerHTML
      }
    : { children };

  if (!hasCaption) return null;

  return <MediaCaption {...captionProps} />;
};

MediaCaptionElement.propTypes = {
  children: T.node,
  dangerouslySetInnerHTML: T.object
};

const Media = (props) => {
  const {
    className,
    size,
    decoration,
    src,
    alt,
    width,
    height,
    attribution,
    fluid
  } = props;

  validateDeco(size, decoration);

  // The MediaItem has to change according to the size parameter.
  // For defined sizes we have to extend the Gridder so we can take
  // advantage of its grid. By default it is just null, i.e. a div.
  const MediaItemExtend = size !== 'default' ? Gridder : MediaItemDefault;

  return (
    <MediaSelf className={className} size={size}>
      <MediaItem as={MediaItemExtend} size={size} decoration={decoration}>
        <MediaItemInner size={size}>
          {fluid ? (
            <GatsbyImage
              imgStyle={{ objectPosition: 'top' }}
              fluid={fluid}
              alt={alt}
            />
          ) : (
            <img src={src} alt={alt} width={width} height={height} />
          )}
          <MediaAttributionInfo attribution={attribution} />
        </MediaItemInner>
      </MediaItem>
      <MediaCaptionElement {...props} />
    </MediaSelf>
  );
};

Media.propTypes = {
  className: T.string,
  size: T.oneOf(['large', 'cover', 'default']),
  decoration: T.oneOf(['left', 'right', 'top', 'bottom', 'none']),
  src: T.string,
  fluid: T.object,
  alt: T.string,
  width: T.oneOfType([T.string, T.number]),
  height: T.oneOfType([T.string, T.number]),
  attribution: T.shape({
    name: T.string,
    url: T.string
  }),
  children: T.node,
  dangerouslySetInnerHTML: T.object
};

Media.defaultProps = {
  size: 'default',
  decoration: 'none'
};

export default Media;

export const MediaVideo = (props) => {
  const { className, size, src, attribution } = props;

  // The MediaItem has to change according to the size parameter.
  // For defined sizes we have to extend the Gridder so we can take
  // advantage of its grid. By default it is just null, i.e. a div.
  const MediaItemExtend = size !== 'default' ? Gridder : MediaItemDefault;

  return (
    <MediaSelf className={className} size={size}>
      <MediaItem as={MediaItemExtend} size={size}>
        <MediaItemInner size={size}>
          <video width={size !== 'default' ? '100%' : 'auto'} controls>
            <source src={src} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <MediaAttributionInfo attribution={attribution} />
        </MediaItemInner>
      </MediaItem>
      <MediaCaptionElement {...props} />
    </MediaSelf>
  );
};

MediaVideo.propTypes = {
  className: T.string,
  size: T.oneOf(['large', 'default']),
  src: T.string,
  attribution: T.shape({
    name: T.string,
    url: T.string
  }),
  children: T.node,
  dangerouslySetInnerHTML: T.object
};

MediaVideo.defaultProps = {
  size: 'default'
};
