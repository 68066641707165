import React from 'react';
import PropTypes from 'prop-types';

import Constrainer from '../../styles/constrainer';

import Media from '../../components/media';
import MediaSandboxCover from '../../media/layout/sandbox/sandbox-ph--cover.jpg';
import MediaSandboxLarge from '../../media/layout/sandbox/sandbox-ph--large.png';
import MediaSandboxUnset from '../../media/layout/sandbox/sandbox-ph--unset.png';

import SandboxLayout from './_layout';

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

const SandboxMedia = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox'>
      <Constrainer>
        <h2>Media</h2>
        <p>Lorem ipsum dolor sit amet.</p>
      </Constrainer>
      <Media
        size='cover'
        decoration='top'
        src={MediaSandboxCover}
        alt='Team meeting'
        attribution={{
          name: 'Lorem ipsum',
          url: 'http://google.com'
        }}
      >
        This is a caption. Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Vivamus luctus aliquam consequat. Nam id elementum lacus, egestas
        sagittis tellus.
      </Media>
      <Media
        size='cover'
        decoration='bottom'
        src={MediaSandboxCover}
        alt='Team meeting'
        attribution={{
          name: 'Lorem ipsum',
          url: 'http://google.com'
        }}
      >
        This is a caption. Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Vivamus luctus aliquam consequat. Nam id elementum lacus, egestas
        sagittis tellus.
      </Media>
      <Media
        size='large'
        decoration='left'
        src={MediaSandboxLarge}
        alt='Team meeting'
        attribution={{
          name: 'Lorem ipsum',
          url: 'http://google.com'
        }}
      >
        This is a caption. Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Vivamus luctus aliquam consequat. Nam id elementum lacus, egestas
        sagittis tellus.
      </Media>
      <Media
        size='large'
        decoration='right'
        src={MediaSandboxLarge}
        alt='Team meeting'
        attribution={{
          name: 'Lorem ipsum',
          url: 'http://google.com'
        }}
      >
        This is a caption. Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Vivamus luctus aliquam consequat. Nam id elementum lacus, egestas
        sagittis tellus.
      </Media>
      <Constrainer>
        <Media
          decoration='none'
          src={MediaSandboxUnset}
          alt='Team meeting'
          attribution={{
            name: 'ipsum dolor sit amet consectetur adipiscing',
            url: 'http://google.com'
          }}
        >
          This is a caption. Lorem ipsum dolor sit amet, consectetur adipiscing
          elit. Vivamus luctus aliquam consequat. Nam id elementum lacus,
          egestas sagittis tellus.
        </Media>
      </Constrainer>
    </SandboxLayout>
  );
};

export default SandboxMedia;

SandboxMedia.propTypes = {
  location: PropTypes.object
};
